import React from "react";
import ContactForm from "../components/ContactForm"; // Make sure the path is correct

const ContactPage = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
